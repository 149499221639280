const injectCSS = (css) => {
  let c0s1s0R2o2o3t = document.getElementById("load_extra_css");
  if (!c0s1s0R2o2o3t) return;
  c0s1s0R2o2o3t.innerHTML = "";
  let s2t0y2l3e = document.createElement("style");
  s2t0y2l3e.type = "text/css";
  s2t0y2l3e.textContent = css;
  c0s1s0R2o2o3t.appendChild(s2t0y2l3e);
  //   document.head.appendChild(el);
};

const injectJS = (js) => {
  let j2s0R2o5o0t = document.getElementById("load_extra_js");
  if (!j2s0R2o5o0t) return;
  j2s0R2o5o0t.innerHTML = "";
  let j0s1R0o3o2t5 = document.createElement("script");
  j0s1R0o3o2t5.type = "text/javascript";
  j0s1R0o3o2t5.defer = true;
  j0s1R0o3o2t5.textContent = js;
  j2s0R2o5o0t.appendChild(j0s1R0o3o2t5);
  //   document.head.appendChild(el);
};

const waitforsometime = (time = 5000) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(`Promise resolved after ${time}ms`);
    }, time);
  });
};

// function printDivToPDF(divId) {
//   // var doc = new jsPDF();
//   // var content = document.getElementById(divId).innerHTML;
//   // doc.html(content, {
//   //   callback: function (doc) {
//   //     doc.save();
//   //   },
//   //   x: 5,
//   //   y: 5
//   // });
//   const { jsPDF } = window.jspdf;
//   var doc = new jsPDF({
//     orientation: "landscape",
//     unit: "mm", // set units to millimeters
//     format: "a4" // set page format to A4
//   });

//   // Add content to the PDF
//   // doc.text("add your heading etc here", 10, 10);
//   doc.autoTable({
//     // html: `#dowTable`, //give table id here
//     html: `#${divId}`, //give table id here
//     theme: "plain",
//     styles: {
//       fontSize: 6, // Adjust font size to fit content
//       cellPadding: 0.5, // Add padding to cells
//       lineWidth: 0.2, // Add border to cells
//       lineColor: [120, 120, 120]
//     },
//     margin: { top: 30, right: 5, bottom: 0, left: 5 },
//     cellStyles: {
//       align: "center"
//     },
//     headStyles: { align: "center" },
//     callback: function (doc) {
//       doc.save();
//     }
//   });

//   // Save the PDF
//   doc.save("document.pdf");
// }

function sortAppListByStatus(appList, priorityStatus = "live") {
  return appList.sort((a, b) => {
    if (a.status === priorityStatus && b.status !== priorityStatus) {
      return -1;
    } else if (a.status !== priorityStatus && b.status === priorityStatus) {
      return 1;
    } else {
      const appA = String(a.name).trim().toLowerCase();
      const appB = String(b.name).trim().toLowerCase();
      return appA.localeCompare(appB);
    }
  });
}

export { injectCSS, injectJS, waitforsometime, sortAppListByStatus };

// window.printDivToPDF = printDivToPDF;
