import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppsListFunction } from "../redux/appview/appviewaction";
import Navbar from "../components/AppBar/Navbar";
import { getStorageItem, removeStorageItem, setStorageItem } from "../utils/storageFunction";
import { getApiCallDetailsPath, getZinoDomainAppData } from "../utils/apiCallFunction";
import { useNavigate } from "react-router-dom";
import { sortAppListByStatus } from "../utils/loadfiles";

const AppList = () => {
  const { dashboardAppsList = [] } = useSelector((state) => state.appviewstore);
  const { isMobileUserAgent } = useSelector((state) => state.userviewstore);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pageLoadFlag, setPageLoadFlag] = useState(false);
  const getUserMetadata = async () => {
    setPageLoadFlag(true);
    await dispatch(getAppsListFunction());
    setPageLoadFlag(false);
  };

  useEffect(() => {
    const cssroot = document.getElementById("cssroot");
    if (cssroot) cssroot.innerHTML = "";

    const load_extra_css = document.getElementById("load_extra_css");
    if (load_extra_css) load_extra_css.innerHTML = "";

    const maproot = document.getElementById("maproot");

    if (maproot) maproot.innerHTML = "";

    const load_extra_js = document.getElementById("load_extra_js");
    if (load_extra_js) load_extra_js.innerHTML = "";

    document.title = `${getZinoDomainAppData()?.title || "Zino"} || Applist`;

    removeStorageItem("", [
      "Zino_app_id",
      "Zino_domain_web",
      "Zino_app_name",
      "Zino_app_is_public",
      "Zino_app_data",
      "Zino_app_theme_id",
      "Zino_app_module_header",
      "selectedApp"
    ]);

    getUserMetadata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dashboardAppsList && dashboardAppsList.length === 1) {
      openMyApp(dashboardAppsList[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardAppsList]);

  const openMyApp = (appItem) => {
    let zino_app_user = getStorageItem("Zino_app_user");

    if (!zino_app_user) {
      navigate("/logout");
    }

    if (appItem.status !== "live") {
      return;
    }

    let localAppProtocol = window.location.protocol;
    let localAppHostname = window.location.hostname;

    let zino_app_user_go_to = getStorageItem("Zino_app_user_go_to") || `${window.location.origin}/applist`;

    let token_data = btoa(
      JSON.stringify({
        Zino_app_user: getStorageItem("Zino_app_user"),
        Zino_app_user_go_to: zino_app_user_go_to,
        Zino_app_owner_domain: `${window.location.origin}`,
        selectedApp: appItem,
        Zino_app_id: `${appItem.id}`,
        Zino_domain_web: `${appItem.domain_web}/`,
        Zino_app_name: `${appItem.name}`,
        Zino_app_is_public: `${appItem.is_admin}`,
        Zino_app_module_header: `default`,
        Zino_domain_app_data: getZinoDomainAppData(),
        Zino_public_apps: false
      })
    );

    let environmentValue = process.env.REACT_APP_ENVIRONMENT || "";
    let localAppDomain;

    if (environmentValue === "local") {
      if (isMobileUserAgent) {
        localAppDomain = `${localAppProtocol}//${localAppHostname}:3002`;
      } else {
        localAppDomain = `${localAppProtocol}//${localAppHostname}:3001`;
      }

      window.open(`${localAppDomain}/${appItem.id}/verification-token?token=${token_data}`, "_self");
    } else {
      localAppDomain = window.location.origin;

      setStorageItem("Zino_app_id", `${appItem.id}`);
      setStorageItem("Zino_domain_web", `${appItem.domain_web}/`);
      setStorageItem("Zino_app_name", `${appItem.name}`);
      setStorageItem("Zino_app_is_public", `${appItem.is_admin}`);
      setStorageItem("Zino_app_module_header", `default`);
      setStorageItem("Zino_app_owner_domain", `${window.location.origin}`);
      setStorageItem("Zino_app_user_go_to", zino_app_user_go_to);
      setStorageItem("selectedApp", JSON.stringify(appItem));

      window.open(`${localAppDomain}/${appItem.id}/home`, "_self");
    }

    if (isMobileUserAgent) {
      // if (environmentValue === "local") {
      //   let localAppDomain = "http://localhost:3002";
      //   window.open(`${localAppDomain}/${appItem.id}/verification-token?token=${token_data}`, "_self");
      // } else {
      //   setStorageItem("selectedApp", JSON.stringify(appItem));
      //   setStorageItem("Zino_domain_app_data", `${JSON.stringify(getZinoDomainAppData())}`);
      //   setStorageItem("Zino_app_module_header", `default`);
      //   setStorageItem("Zino_app_owner_domain", `${window.location.origin}`);
      //   window.open(`${appItem.domain_web}/${appItem.id}/home`, "_self");
      // }

      if (environmentValue === "local") {
        let localAppDomain = `${localAppProtocol}//${localAppHostname}:3002`;
        window.open(`${localAppDomain}/${appItem.id}/verification-token?token=${token_data}`, "_self");
      } else {
        // localAppDomain = appItem.domain_mobile;
        let localAppDomain = window.location.origin;

        setStorageItem("Zino_app_owner_domain", `${window.location.origin}`);
        setStorageItem("selectedApp", JSON.stringify(appItem));
        window.open(`${localAppDomain}/${appItem.id}/home`, "_self");
      }
    } else {
      let localAppDomain;
      if (environmentValue === "local") {
        localAppDomain = `${localAppProtocol}//${localAppHostname}:3001`;
        window.open(`${localAppDomain}/${appItem.id}/verification-token?token=${token_data}`, "_self");
      } else {
        // localAppDomain = appItem.domain_web;
        localAppDomain = window.location.origin;

        setStorageItem("Zino_app_id", `${appItem.id}`);
        setStorageItem("Zino_domain_web", `${appItem.domain_web}/`);
        setStorageItem("Zino_app_name", `${appItem.name}`);
        setStorageItem("Zino_app_is_public", `${appItem.is_admin}`);
        setStorageItem("Zino_app_module_header", `default`);
        window.open(`${localAppDomain}/${appItem.id}/home`, "_self");
      }
      // if (environmentValue === "local") {
      //   let localAppDomain = "http://localhost:3001";
      //   window.open(`${localAppDomain}/${appItem.id}/verification-token?token=${token_data}`, "_self");
      // } else {
      //   setStorageItem("Zino_app_id", `${appItem.id}`);
      //   setStorageItem("Zino_domain_web", `${appItem.domain_web}/`);
      //   setStorageItem("Zino_app_name", `${appItem.name}`);
      //   setStorageItem("Zino_app_is_public", `${appItem.is_admin}`);
      //   setStorageItem("Zino_domain_app_data", `${JSON.stringify(getZinoDomainAppData())}`);
      //   setStorageItem("Zino_app_module_header", `default`);
      //   setStorageItem("Zino_app_owner_domain", `${window.location.origin}`);
      //   window.open(`${appItem.domain_web}/${appItem.id}/home`, "_self");
      // }
    }
  };

  const sortedDashboardAppsList =
    dashboardAppsList && dashboardAppsList.length > 1 ? sortAppListByStatus(dashboardAppsList) : dashboardAppsList;

  if (pageLoadFlag || (dashboardAppsList && dashboardAppsList.length === 1)) {
    return (
      <div className="zino_renderer_loader_box">
        <span className="zino_renderer_loader"></span>
      </div>
    );
  } else {
    return (
      <>
        <Navbar headerConfig={{}} />
        <div className={isMobileUserAgent ? "mobile_applist_outerbox" : "applist_outerbox"}>
          {sortedDashboardAppsList?.map((appItem) => {
            return (
              <div
                key={appItem.id}
                className={appItem.status !== "live" ? "applist_item applist_item_disable" : "applist_item"}
                onClick={() => openMyApp(appItem)}
              >
                <div className="logo_container">
                  <img
                    src={
                      appItem.logo !== ""
                        ? `${getApiCallDetailsPath()}/api/v1/public/${appItem.logo}`
                        : require("../Images/deployed_code.jpg")
                    }
                    alt={appItem.name}
                    className="logo_style"
                    onError={(event) => {
                      event.target.src = require("../Images/deployed_code.jpg");
                      event.onerror = null;
                    }}
                  />
                </div>
                <div className="app_details">
                  <p className="app_title">{appItem.name}</p>
                  {isMobileUserAgent ? "" : <p className="app_description">{appItem.description}</p>}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
};

export default AppList;
