import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApiCallDetailsPath, getZinoDomainAppData } from "../../utils/apiCallFunction";
import { removeStorageItem, setStorageItem } from "../../utils/storageFunction";
import { getAppsListForPublicScreensFunction } from "../../redux/appview/appviewaction";
import Navbar from "../../components/AppBar/Navbar";
import { sortAppListByStatus } from "../../utils/loadfiles";

const PublicScreens = () => {
  const { isMobileUserAgent } = useSelector((state) => state.userviewstore);
  const { dashboardPublicAppsList = [] } = useSelector((state) => state.appviewstore);
  const dispatch = useDispatch();

  const [pageLoadFlag, setPageLoadFlag] = useState(false);
  const getUserMetadata = async () => {
    setPageLoadFlag(true);
    await dispatch(getAppsListForPublicScreensFunction());
    setPageLoadFlag(false);
  };

  useEffect(() => {
    // const cssRoot = document.getElementById("cssroot");
    // cssRoot.innerHTML = "";
    // const mapRoot = document.getElementById("maproot");
    // mapRoot.innerHTML = "";
    document.title = `${getZinoDomainAppData()?.title || "Zino"} || Applist`;

    removeStorageItem("", [
      "Zino_public_app_id",
      "Zino_public_domain",
      "Zino_public_app_name",
      "Zino_public_app_config",
      "Zino_app_user_go_to"
    ]);

    getUserMetadata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openMyApp = (appItem) => {
    if (appItem.status !== "live") {
      return;
    }

    let localAppProtocol = window.location.protocol;
    let localAppHostname = window.location.hostname;

    let environmentValue = process.env.REACT_APP_ENVIRONMENT || "";
    let localAppDomain;

    if (environmentValue === "local") {
      let token_data = btoa(
        JSON.stringify({
          Zino_public_app_config: appItem,
          Zino_public_app_id: appItem.id,
          Zino_public_app_name: appItem.name,
          Zino_public_domain_web: `${appItem.domain_web}/`,
          Zino_public_domain: `${window.location.origin}/`,
          Zino_app_user_go_to: `${window.location.origin}/`,
          Zino_public_apps: true
        })
      );

      if (isMobileUserAgent) {
        localAppDomain = `${localAppProtocol}//${localAppHostname}:3002`;
      } else {
        localAppDomain = `${localAppProtocol}//${localAppHostname}:3001`;
      }

      window.open(`${localAppDomain}/${appItem.id}/verification-token?token=${token_data}`, "_self");
    } else {
      localAppDomain = window.location.origin;

      setStorageItem("Zino_public_app_id", `${appItem.id}`);
      setStorageItem("Zino_public_app_name", `${appItem.name}`);
      setStorageItem("Zino_public_domain_web", `${appItem.domain_web}/`);
      setStorageItem("Zino_public_app_config", JSON.stringify(appItem));
      setStorageItem("Zino_public_domain", `${localAppDomain}/`);
      setStorageItem("Zino_app_user_go_to", `${localAppDomain}/`);

      window.open(`${localAppDomain}/${appItem.id}/public/pages`, "_self");
    }
  };
  // const openMyApp = (appItem, index = 0) => {
  //   if (appItem.status !== "live") {
  //     return;
  //   }
  //   setStorageItem("Zino_public_app_id", `${appItem.id}`);
  //   setStorageItem("Zino_public_domain_web", `${appItem.domain_web}/`);
  //   setStorageItem("Zino_public_app_name", `${appItem.name}`);
  //   navigate(`/public/${appItem?.public_screens?.[index] || "applist"}`);
  // };

  const sortedDashboardPublicAppList =
    dashboardPublicAppsList && dashboardPublicAppsList.length > 1
      ? sortAppListByStatus(dashboardPublicAppsList)
      : dashboardPublicAppsList;

  if (pageLoadFlag) {
    return (
      <div className="zino_renderer_loader_box">
        <span className="zino_renderer_loader"></span>
      </div>
    );
  } else {
    return (
      <>
        <Navbar rootType={"public"} headerConfig={{}} />
        <div className={isMobileUserAgent ? "mobile_applist_outerbox" : "applist_outerbox"}>
          {sortedDashboardPublicAppList?.map((appItem) => {
            return (
              <div
                key={appItem.id}
                className={appItem.status !== "live" ? "applist_item applist_item_disable" : "applist_item"}
                onClick={() => openMyApp(appItem)}
              >
                <div className="logo_container">
                  <img
                    src={
                      appItem.logo !== ""
                        ? `${getApiCallDetailsPath()}/api/v1/public/${appItem.logo}`
                        : require("../../Images/deployed_code.jpg")
                    }
                    alt={appItem.name}
                    className="logo_style"
                    onError={(event) => {
                      event.target.src = require("../../Images/deployed_code.jpg");
                      event.onerror = null;
                    }}
                  />
                </div>
                <div className="app_details">
                  <p className="app_title">{appItem.name}</p>
                  {isMobileUserAgent ? "" : <p className="app_description">{appItem.description}</p>}
                </div>
              </div>
            );
            // let public_screens = appItem?.public_screens || [];
            // return (
            //   <React.Fragment key={appItem.id}>
            //     {public_screens.map((screen, screenIndex) => {
            //       return (
            //         <div
            //           key={appItem.id + screen + screenIndex}
            //           onClick={() => openMyApp(appItem, screenIndex)}
            //           style={{
            //             width: "100%",
            //             padding: "8px",
            //             border: "1px solid #bdbdbd",
            //             borderRadius: "8px",
            //             display: "flex",
            //             gap: "8px",
            //             cursor: "pointer"
            //           }}
            //         >
            //           <p className="app_title">{screen}</p>-<p className="app_description">{appItem.name}</p>
            //         </div>
            //       );
            //     })}
            //   </React.Fragment>
            // );
          })}
        </div>
      </>
    );
  }
};

export default PublicScreens;
